import React from 'react';
import { formatCurrency } from '_base';
import { usePurchaseOrder } from 'contexts/purchasing/purchaseOrder';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePersonalInfo } from 'contexts/purchasing/personalInfo';
import { useRegistrationInfo } from 'contexts/purchasing/registrationInfo';

function VendorSummary() {
    const { description, 
        serviceFee, 
        taxes, 
        spacesTotal,  
        discountTotal,
        grandTotal,
        completed,
        resetPO,
    } = usePurchaseOrder();
    const { resetPi } = usePersonalInfo();
    const { resetRi } = useRegistrationInfo();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const cancelHandler = () => {
        resetPO();
        resetRi();
        resetPi();
        if (pathname.indexOf('member') > -1) {
            navigate('/portal/member/events');
        } else {
            navigate('/portal/events');
        }
    }

    const finishHandler = () => {
        if (pathname.indexOf('member') > -1) {
            navigate('/portal/member/events');
        } else {
            navigate('/portal/events');
        }
    }

    return (
        <div className='registration-summary'>
            <div className='registration-summary-title'>Order Summary</div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Spaces</div>
                <div className='item-amount'>{formatCurrency(spacesTotal)}</div>
            </div>
            <hr/>
            <div className='registration-summary-item'>
                <div className='item-title'>Sub-total</div>
                <div className='item-amount'>{formatCurrency(spacesTotal)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Service fee</div>
                <div className='item-amount'>{formatCurrency(serviceFee)}</div>
            </div>
            <div className='registration-summary-item'>
                <div className='item-title'>Taxes</div>
                <div className='item-amount'>{formatCurrency(taxes)}</div>
            </div>
            <div className='registration-summary-total'>
                <div className='item-title'>Total</div>
                <div className='item-amount'>{formatCurrency(grandTotal)}</div>
            </div>
            <div className='registration-summary-buttons'>
                <button className='secondary' onClick={cancelHandler}>Cancel</button>
                <button disabled={!completed} onClick={finishHandler}>Finished</button>
            </div>
        </div>
    )
}

export default VendorSummary;