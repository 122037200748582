import React, { useEffect, useState } from 'react';
import ChildLogins from './childLogins';
import AppleButton from "assets/Download_on_the_App_Store_Badge.svg";
import GoogleButton from "assets/Google_Play_Store_badge.png";
import { useLang } from 'contexts/lang';
import { useSession } from 'contexts/session';
import { useAuth } from 'contexts/auth';
import { ChildLoginsProvider } from 'contexts/childLogins';

function Settings() {
    const { tenant, memberTypes } = useSession();
    const { member, isExpired } = useAuth();
    const { dict } = useLang();
    const [error, setError] = useState(null);
    const [info, setInfo] = useState(null);
    const [memberType, setMemberType] = useState(null);

    const [moderator, setModerator] = useState(false);

    useEffect(() => {
        if (memberTypes.length > 0 && member) {
            setMemberType(memberTypes.find((mt) => mt.id === member.type));
        }
    }, [memberTypes, member]);


    const clearMessages = () => {
        setError(null)
        setInfo(null);
    }
    
    return(
        <>
        {member && 
            <div className='settings-view'>
                {error && 
                    <div style={{position:'absolute',top:'20px',left:'20px',right:'20px',display:'flex',flexFlow:'row nowrap',justifyContent:'space-between',background:'red',color:'white'}}>
                        <div style={{padding:'20px'}}>{error}</div>
                        <div role='button' onClick={clearMessages} style={{padding:'20px',cursor:'pointer'}}>X</div>
                    </div>
                }
                {info && 
                    <div style={{position:'absolute',top:'20px',left:'20px',right:'20px',display:'flex',flexFlow:'row nowrap',justifyContent:'space-between',background:'green',color:'white'}}>
                        <div style={{padding:'20px'}}>{info}</div>
                        <div role='button' onClick={clearMessages} style={{padding:'20px',cursor:'pointer'}}>X</div>
                    </div>
                }
                
                {(memberType && memberType.allowChildren && !isExpired) &&
                <div className='setting-item'>
                    <hr />
                    <div className='setting-item' style={{"width":"100%"}}>
                    <h3>{dict.additionalLogins}</h3>
                    <ChildLoginsProvider>
                        <ChildLogins />
                    </ChildLoginsProvider>
                    </div>
                </div>
                }
                <hr />
                <div className='setting-item'>
                    <h3>{dict.mobileApp}</h3>
                    <p>{dict.mobileApp1}</p>
                    <p>{dict.mobileApp2}<span style={{fontWeight:'bolder'}}>{tenant.subdomain}</span> {dict.mobileApp3}</p>
                    <div style={{display:'flex',flexFlow:'row nowrap',justifyContent:'space-evenly'}}>
                    <img 
                        src={AppleButton} 
                        style={{height:'40px',width:'120px',cursor:'pointer'}} 
                        role='button'
                        alt='Download on the Apple app store'
                        onClick={() => {window.open('https://apps.apple.com/us/app/middletwin-member-portal/id1561525387');}}>    
                    </img>

                    <img 
                        src={GoogleButton} 
                        style={{height:'40px',width:'120px',cursor:'pointer'}} 
                        role='button'
                        alt='Download on the Google play store'
                        onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.middletwin.memberportal');}}>
                    </img>
                    </div>
                </div>
            </div>
        }
        </>
    )
}


export default Settings;